export interface Theme {
    name: string;
    themeColor: string;
}


export const Themes: Theme[] =
    [
        {
            name: 'dark',
            themeColor: '#003865',
        },
        {
            name: 'light',
            themeColor: '#1f4fa4',
        },
    ]
