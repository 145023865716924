import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Theme, Themes } from '../models/theme/theme.model';

@Injectable()
export class ThemeService {
    getAll(): Observable<Theme[]> {
        return of(Themes);
    }


    getCurrent(): Theme {
        const current = Themes.find(f => f.name == localStorage.getItem('theme')) || Themes[0];
        return current;
    }


    setTheme(theme: Theme): void {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme.themeColor);
        document.querySelectorAll('app-toolbar, app-sidenav').forEach(e => e.setAttribute('class', theme.name))
        localStorage.setItem('theme', theme.name);
    }


    setDefault(): void {
        this.setTheme(
            this.getCurrent()
        )

    }

}
