import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-notifications-button',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    AsyncPipe,
    MatSlideToggleModule,
  ],
  templateUrl: './notifications-button.component.html',
  styleUrl: './notifications-button.component.scss'
})

export class NotificationsButtonComponent {
  public permissionState: PermissionState = 'prompt';
  public permissionState$: Observable<PermissionState>;
  constructor(
    private readonly notificationService: NotificationService,
  ) {
    this.permissionState$ = this.notificationService.getNotificationsState();
  }


  async requestNotifications(): Promise<void> {
    await Notification.requestPermission();
    this.permissionState$ = this.notificationService.getNotificationsState();
  }

}
