import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Theme } from '../../../models/theme/theme.model';
import { ThemeService } from '../../../services/theme.service';
import { NotificationsButtonComponent } from "./notifications-button/notifications-button.component";


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
    NotificationsButtonComponent
  ]
})
export class AccountComponent {
  public dataForm: FormGroup;
  public themes$: Observable<Theme[]>;

  public dataSeparators = [
    { name: 'comma', value: ',' },
    { name: 'semicolon', value: ';' },
  ]
  public languages = [
    { name: 'English 🇬🇧', value: 'en-US' },
    { name: 'Polski 🇵🇱', value: 'pl-PL' },
  ];

  public offlineAccess = false;
  constructor(
    private readonly themeService: ThemeService,
    private readonly translate: TranslateService,
  ) {
    this.themes$ = this.themeService.getAll();
    this.dataForm = new FormGroup({
      language: new FormControl(this.translate.currentLang),
      theme: new FormControl(this.themeService.getCurrent()),
      separator: new FormControl(localStorage.getItem('separator') || this.dataSeparators[0].value),
    });
    
    this.offlineAccess = JSON.parse(localStorage.getItem('offlineAccess') || 'false');

    this.dataForm.get('separator')?.valueChanges.subscribe(
      data => localStorage.setItem('separator', data)
    )

    this.dataForm.get('language')?.valueChanges.subscribe(
      lang => {
        localStorage.setItem('language', lang);
        this.translate.use(lang)
      }
    )

    this.dataForm.get('theme')?.valueChanges.subscribe(
      (data: Theme) => this.themeService.setTheme(data)
    )
  }


}
